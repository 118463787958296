.error-msg {
  font-size: 12px;
  color: red;
}
.custom_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  gap: 8px;
}

.search_con {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.search_con .search_input {
  /* padding-right: 15px; */
}

.search_con svg {
  position: absolute;
  right: 0;
  bottom: 17%;
  font-size: 18px;
}

.button-outlined {
  color: "#fff" !important;
}


.datePicker-custom{
  width: 100%;
}

.datePicker-custom + p ,.error-custom{
  margin-left: 6px;
  color: red;
}

.DeleteIcon-custom{
  float: right;
  margin-top: -10px;
}

.uploadAudiobox{
  width: 100%;
  height: 45px;
  padding: 11px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #dbe0e4; 
}

.question-box{
  border: 1px solid #dbe0e4;
  padding: 24px;
  border-radius: 10px;
  margin-left: 24px;
}

.question-spacing{
  margin-top: 18px;
}

.progressbar{
  width: 100%;
}

.jodit-react-container .jodit-container .jodit-workplace .jodit-wysiwyg ul {
  display: block !important;
  margin-left: 20px; 
}

.jodit-react-container .jodit-container .jodit-workplace .jodit-wysiwyg ul li{
  margin-left: 20px; 
}

.html-template.linkdisable a {
  pointer-events: none;
  cursor: pointer;
}

.swal2-container.swal2-center {
  z-index: 100000 !important;
}
body{background:url(../../public/assets/images/bg.png) repeat}
header {
  border-bottom: 2px solid rgb(255, 217, 198);
  background-color: #fffcfa !important;
}
nav .MuiDrawer-root .MuiDrawer-paper {
  background: #fffcfa;
  border:none;
}

.view-score-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fffaf8;
  border: solid 1px #ffd9c6;
  border-radius: 10px;
  padding: 15px;
}
.loginPageDesign{
  background: #fffcfa;
  border: solid 1px #ffd9c6;
  padding: 30px;
  width: 100%;
  margin: auto;
  border-radius: 20px;
}

.view-score-list.custom-performacediv {
  display: block;
}
.view-score-list.custom-performacediv span{
  float: right;
}
a.dashboard-link-design {
  text-decoration: none;
}

.ascoder{
  display: none;
}

.custom-icon-list:hover .ascoder {
  display: block;
  padding: 0;
  margin: -5px;
}

span.ascoder svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  font-size: xx-large;
  margin: 0;
  padding: 0px;
}